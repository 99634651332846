.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  margin-bottom: 0px;
}


#signupform {
  width: 90%;
  max-width: 400px;
  text-align: left;
}

/* Style inputs */
input[type=text], select {
  font-size: calc(10px + 2vmin);
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Style the submit button */
input[type=submit] {
  font-size: calc(10px + 2vmin);  width: 100%;
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 12px 0px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Add a background color to the submit button on mouse-over */
input[type=submit]:hover {
  background-color: #45a049;
}

#success-message {
  color: #04AA6D;
}
#error-message {
  color: red;
}

p {
  margin: 0 auto 1rem auto;
  font-size: .8rem;
}
